require('./theme');

jQuery(function ($) {
  $('.cep').inputmask('99999-999');
  $('.date').inputmask('99/99/9999');
  $('.cpf').inputmask('999.999.999-99');
  $('.phone, #phone_alt').inputmask({
    mask: ['(99) 9999-9999', '(99) 99999-9999'],
    keepStatic: true,
  });
});
